.rectangle-container {
  width: 489px;
  height: 95px;
  background: #f5f7ff;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.85);

  align-items: center;
}

.bg-grey {
  background: rgba(237, 237, 237, 0.37);
}

.bg-green {
  height: 40px;
  background: #defc77;
}

.text-green {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.0015em;

  color: #00c67e;
}

.text-red {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.0015em;

  color: #eb1e4b;
}

.offer-div {
  width: 100%;
  height: 70px;
  border-radius: 0px 18px;
  border: 1px solid #c5161e;

  font-weight: 400;
  font-size: 13px;
  line-height: 22px;

  letter-spacing: 0.15px;

  color: #c5161e;
}

.model-div {
  width: 118px;
  height: 30px;

  border: 1px solid #ff8a00;
}

.text-qty {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #0c0c0c;
}

.delivery-div {
  width: 136px;
  height: 44px;
  border: 1px solid rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: 0.1px;
}

.topic-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  /* identical to box height, or 138% */

  /* Dark Colors/Rich Black */

  color: #0c0c0c;
}

.details-titles {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.related {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.0015em;
}

.related-div {
  height: 137px;

  background: #ffffff;
  /* Drop Shadow */

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.product-reviews-nb {
  width: 126px;
  height: 18px;
  padding: 5px 10px 5px 10px;
  border-radius: 29.92px;
  gap: 4.27px;
  background: #00B2FF;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-rating {
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
}

.review-delete-btn {
  box-shadow: 0px 1px 8px 0px #C2C2C254;
  border: none
}

.equal-height-row {
  display: flex;
  flex-wrap: wrap;
}

.equal-height-row>.ant-col {
  display: flex;
  flex-direction: column;
}

.equal-height-row>.ant-col>div {
  flex: 1;
}