.container_old {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 17px;
  background-color: white;
}

.container {
  position: relative;
  border-radius: 30px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 10px;
  &:hover {
    .action_buttons_container {
      display: block;
    }
  }
}

.purchase_history__a {
  color: #090038;
  text-decoration: underline;
}

.purchase_history__a:hover {
  color: #090038;
  text-decoration: underline;
}

.action_buttons_container {
  display: none;
}

.action_button {
  position: absolute;
}

.action_button img {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 500px) {
  .action_buttons_container {
    display: block;
  }
  .action_button img {
    width: 20px;
    height: 20px;
  }
}
