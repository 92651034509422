.emolo_title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.0015em;
}

.emolo_title_1 {
  color: var(--primary-color);
}

.emolo_title_2 {
  color: var(--black-text-color);
  font-size: 18px;
}