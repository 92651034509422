@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* @font-face {
  font-family: TajawalRegular;
  src: url("../assets/fonts/Tajawal-Regular.ttf");
} */
html,
body,
#root {
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: gainsboro;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #a9a9a9;
  }
}

:root {
  --primary-color: #00b2ff;
  --secondary-color: #eb1e4b;
  --secondary-1-color: #ff8a00;
  --black-text-color: #474747;
  --text-placeholder: #cdcdcd;
  --sub-text-color: #8a8a8a;
  --white-text-color: #fff;
  --gray-text-color: #929292;
  --green-text-color: #00c67e;
  --color-10: #666666;
  --secondary-red-color: #ff2a2a;
  --white-text-color: #fff;
  --ligh-blue-color: #f5fcff;
  --orange-pimary-color: #ffa620;
  --border-blue-color: #bddeff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-text-color);
}

button {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

/* start - colors - classes */
.white-text-color {
  color: var(--white-text-color) !important;
}
.secondary-red-color {
  color: var(--secondary-red-color);
}
.green-text-color {
  color: var(--green-text-color) !important;
}
.color-10 {
  color: var(--color-10) !important;
}
.sub-text-color {
  color: var(--sub-text-color) !important;
}
.primary-color {
  color: var(--primary-color) !important;
}
.secondary-color {
  color: var(--secondary-color) !important;
}
.black-text-color {
  color: var(--black-text-color) !important;
}
.gray-color {
  color: var(--gray-text-color) !important;
}
.orange-text-color {
  color: var(--orange-pimary-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}
.bg-light-blue {
  background-color: var(--ligh-blue-color) !important;
}
.bg-orange-primary {
  background-color: var(--orange-pimary-color) !important;
}
.new-blue-color {
  color: #0073b4 !important;
}
.new-orange-color {
  color: #f8931d !important;
}
.new-red-color {
  color: #ff0000 !important;
}
/* end - colors - classes */

.border-green {
  border: 1px solid #52c41a;
  border-radius: 4px;
}

a {
  color: var(--primary-color);
  text-decoration: unset;
}

/* start - fonts - classes */

.bold-12 {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.bold-14 {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.bold-16 {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.bold-18 {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700;
  line-height: normal !important;
}

.bold-20 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.bold-30 {
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.bold-40 {
  font-size: 40px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.regular-12 {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.regular-14 {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.regular-16 {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.regular-18 {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.regular-20 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

@media screen and (max-width: 1023px) {
  .bold-12 {
    font-size: 10px !important;
  }

  .bold-14 {
    font-size: 12px !important;
  }

  .bold-16 {
    font-size: 14px !important;
  }

  .bold-18 {
    font-size: 16px !important;
  }

  .bold-20 {
    font-size: 18px !important;
  }

  .bold-30 {
    font-size: 24px !important;
  }

  .bold-40 {
    font-size: 30px !important;
  }

  .regular-12 {
    font-size: 10px !important;
  }

  .regular-14 {
    font-size: 12px !important;
  }

  .regular-16 {
    font-size: 14px !important;
  }

  .regular-18 {
    font-size: 16px !important;
  }

  .regular-20 {
    font-size: 18px !important;
  }

  input,
  select,
  optgroup,
  textarea {
    font-size: 16px !important;
  }
}

/* end - fonts - classes */

/* start - old text classes */
.headline {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  color: #000000;
}

.headline-3 {
  font-weight: normal;
  font-size: 46px;
  letter-spacing: 0px;
  color: black;
}

.headline-4 {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 0.25px;
  color: #000000;
}

.headline-5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 34px;
  color: #000000;
}

.headline-6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.0015em;
  color: #000000;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}

.subtitle-1 {
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: black;
}

.subtitle-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000000;
}
.subtitle-3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #00b2ff;
}
.subtitle-4 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #828282;
}
.subtitle-5 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #00b2ff;
}
.body-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}

.body-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}

.caption {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #000000;
}
/* end - old text classes */

.bg-light {
  background-color: white !important;
}

.bg-ligh-blue {
  background-color: var(--ligh-blue-color);
}

.ant-input:placeholder-shown {
  color: var(--text-placeholder);
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  color: var(--black-text-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.ant-btn {
  padding: 10px;
  border-radius: 4px;
  height: auto;
}

.remove-asterisk
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-page-header-content {
  padding-top: 0px;
}

.ant-divider {
  border-color: #adadad;
}

.cursor-pointer {
  cursor: pointer !important;
}

#home-carousel .ant-carousel .slick-dots li.slick-active button {
  background: #fff !important;
}

.ant-layout {
  background-color: white;
}

.ant-layout-footer {
  background-color: white;
}

.rounded-4 {
  border-radius: 4px !important;
}

.bg-white {
  background-color: white !important;
}

.remove-colon ::after {
  content: none !important;
}

.ant-drawer {
  z-index: 16;
}

thead[class*="ant-table-thead"] th {
  background-color: #eef9ff !important;
  color: #0073b4;
}

/* remove table header separator */
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.grids-text {
  max-width: 95%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  .box-shadow-default {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2) !important;
  }
}
.ant-popover-buttons button {
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-input-number-input-wrap input {
  height: 100%;
}

.ant-input-number {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  width: auto !important;
  height: auto !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
//  shahrour
.rma-return-select {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f5f7ff !important;
  }
}
.rma-form {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.ant-input-affix-wrapper {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 9px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  border-radius: 4px !important;
}

.react-tel-input .form-control {
  border-radius: 4px !important;
  border: 1px solid #c3c3c3 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  padding-top: 25.5px !important;
  padding-bottom: 25.5px !important;
}

.react-tel-input .form-control:focus {
  border-color: #29c6ff !important;
  box-shadow: 0 0 0 2px rgba(0, 178, 255, 0.2) !important;
  border-right-width: 1px !important;
  outline: 0 !important;
}

@media screen and (max-width: 1023px) {
  .react-tel-input .form-control {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.pic {
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 60px !important;
  }
  .ant-upload-list-item-info {
    border-radius: 60px !important;
  }
}

.centered-pic {
  display: flex;
  justify-content: center;
}

.print-source {
  opacity: 0;
}
.print-source div {
  max-height: 0 !important;
}
@media print {
  .print-source {
    opacity: 1;
  }
  .print-source div {
    max-height: initial !important;
  }
}

.container-body {
  margin: 20px 50px 20px 50px;
}

.menu_container .ant-collapse-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.date-range-filter {
  .ant-picker-panel-container .ant-picker-panels {
    display: flex !important ;
    flex-direction: column !important;
  }
}

.table-small-row .ant-table-cell {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.padding-main {
  padding-left: 110px;
  padding-right: 110px;
}

@media screen and (max-width: 1023px) {
  .padding-main {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .ant-modal-body {
    max-height: 80vh;
  }
  .ant-modal-content {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .ant-modal-footer {
    padding-bottom: 0px;
  }
}

.ant-divider {
  border-color: #f2f2f2;
}

.text-clamp-4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 4) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.text-clamp-3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.text-clamp-2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.text-clamp-1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.disabled-link {
  pointer-events: none;
  opacity: 0.4;
  &:hover {
    transform: unset;
    transition: unset;
  }
}

.link-style {
  color: var(--black-text-color);
  &:hover {
    color: var(--primary-color);
  }
}

#home-new-arrival {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

#home-shop-by-category {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bordered {
  border: 1px solid #f1f1f1 !important;
}

.image-selected-bordered {
  border: 3px solid #00b2ff !important;
}

.without-slick-arrows .slick-arrow {
  display: none !important;
}

.ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}

.ant-modal-content {
  border-radius: 20px !important;
}

/**
 * Remove focus ring for non-explicit scenarios.
 */
:focus:not(.focus-visible) {
  outline: 0;
  box-shadow: none;
}

.active-style {
  color: #29c6ff !important;
  border-color: #29c6ff !important;
}

.react-tel-input .form-control {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.disabled-add-to-cart {
  color: var(--black-text-color) !important;
  border: 1px solid var(--black-text-color) !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
