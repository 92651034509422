.container {
  position: -webkit-sticky;
  position: sticky;
  top: 173px;
  align-self: flex-start;
  max-height: calc(100vh - 155px);
  overflow-y: auto;
  .ant-collapse-content {
    border-top: unset;
  }
}
